import axios from "axios";

export const URL = "https://server.soop.asia/api";
export var PHONE_NUMBER:string = '(+60)12-3209012';
export var SINGLE_PHONE:string = '(+60)12-3209012';
export var ADDRESS1:string = 'No.18a, Anggerik Mokara 31/G Kota Kemuning 40460 Shah Alam, Selangor Darul Ehsan';
export var EMAIL:string = 'admin@klpm.my';
export var COMPANY_NAME:string = 'KLPM TRANSPORT MOVERS SDN.BHD';
export var COMPANY_PREFIX:string = 'KLPM';
export const MAP_KEY:string = "AIzaSyACrivko1BWYpVt9GHmD_KfDPUARBwvl6c";
export var lat:number = 3.12057;
export var lng:number = 101.69197;
axios.defaults.baseURL = URL;

